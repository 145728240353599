import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Collapse from '@material-ui/core/Collapse';
import { toast } from 'react-toastify';
import Notification from '../Notification/Notification';
import './ShareArticleForm.scss';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import Button from '../Button/Button';
import Validation from '../../helpers/Validation';
import AuthRequest from '../../api/AuthRequest';

/**
 * This component controls the share article user form
 */
class ShareArticleForm extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      addingRecipient: false,
      recipientEmailOne: '',
      recipientEmailTwo: '',
      recipientEmailThree: '',
      message: ''
    };
    this.addingRecipient = this.addingRecipient.bind(this);
    this.shareArticle = this.shareArticle.bind(this);
    this.handleRecipientEmailOne = this.handleRecipientEmailOne.bind(this);
    this.handleRecipientEmailTwo = this.handleRecipientEmailTwo.bind(this);
    this.handleRecipientEmailThree = this.handleRecipientEmailThree.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
  }

  /**
   * Handles updates to the recipientEmailOne field.
   * @param {object} e - event that contains the data
   */
  handleRecipientEmailOne(e) {
    const { error } = this.state;
    const newError = error;
    if (error && error['Recipient Email One']) {
      delete newError['Recipient Email One'];
    }

    this.setState({
      recipientEmailOne: e.target.value,
      error: newError
    });
  }

  /**
   * Handles updates to the recipientEmailTwo field.
   * @param {object} e - event that contains the data
   */
  handleRecipientEmailTwo(e) {
    const { error } = this.state;
    const newError = error;
    if (error && error['Recipient Email Two']) {
      delete newError['Recipient Email Two'];
    }

    this.setState({
      recipientEmailTwo: e.target.value,
      error: newError
    });
  }

  /**
   * Handles updates to the recipientEmailThree field.
   * @param {object} e - event that contains the data
   */
  handleRecipientEmailThree(e) {
    const { error } = this.state;
    const newError = error;
    if (error && error['Recipient Email Three']) {
      delete newError['Recipient Email Three'];
    }

    this.setState({
      recipientEmailThree: e.target.value,
      error: newError
    });
  }

  /**
   * Handles updates to the message field.
   * @param {object} e - event that contains the data
   */
  handleMessage(e) {
    const wordLength = e.target.value.trim().split(/\s+/).length;
    const { error, message } = this.state;
    const newError = error;
    if (error && error.Message) {
      delete newError.Message;
    }

    this.setState({
      message: wordLength <= 100 ? e.target.value : message,
      error: newError
    });
  }

  /**
   * Calls the API to add a new user.
   * Throws an error if any of the necessary data is missing.
   * Creates a notification if user was sucessfully added or an error was encountered.
   */
  shareArticle() {
    const {
      recipientEmailOne, recipientEmailTwo, recipientEmailThree, message
    } = this.state;

    const {
      title, subtitle, docId, onClose, enlytonID
    } = this.props;

    const validationObject = [
      {
        name: 'recipientEmailOne',
        value: recipientEmailOne,
        validationType: 'required|email',
      },
      {
        name: 'recipientEmailTwo',
        value: recipientEmailTwo,
        validationType: 'email',
      },
      {
        name: 'recipientEmailThree',
        value: recipientEmailThree,
        validationType: 'email',
      }
    ];

    const error = Validation.formValidation(validationObject);

    if (Object.keys(error).length !== 0) {
      this.setState({
        error
      });
      return;
    }

    AuthRequest({
      method: 'post',
      url: 'share-article',
      data: {
        email: {
          recipientEmailOne,
          recipientEmailTwo,
          recipientEmailThree
        },
        body: message,
        title,
        subtitle,
        doc_id: docId,
        enlyton_doc_id: enlytonID
      }
    }).then(() => {
      onClose();
      ReactGA.event({
        category: 'article-view',
        action: 'Share'
      });
      ReactGA4.event('share-article');
      /* toast.success(
        <Notification
          title="Article Shared to Specified Recipients"
          body={''}
        />
      ); */
    });
  }

  /**
   * Changes the component view to show the add user form.
   * Will throw an error if not enough licenses are available.
   */
  addingRecipient() {
    const { addingRecipient } = this.state;
    this.setState({
      addingRecipient: !addingRecipient
    });
  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const { onClose } = this.props;
    const {
      addingRecipient, recipientEmailOne, recipientEmailTwo, recipientEmailThree, message, error
    } = this.state;

    return (
      <>
        <div className="shareArticle_data_input_section">
          <div className="AddUser_col">
            <div className="shareArticle_data">
              <TextField
                id="recipientEmailOne"
                type="text"
                label=""
                autoComplete="email"
                size="small"
                variant="outlined"
                color="primary"
                fullWidth
                value={recipientEmailOne}
                inputProps={{ className: 'shareArticle_input' }}
                InputLabelProps={{ className: 'shareArticle_input_label' }}
                onChange={this.handleRecipientEmailOne}
                error={!!(error && error.recipientEmailOne)}
                helperText={error && error.recipientEmailOne ? error.recipientEmailOne : ' '}
                variant="filled"
              />
            </div>
          </div>
          <div className="AddUser_col">
            <div className="shareArticle_data">
              <TextField
                id="recipientEmailTwo"
                type="text"
                autoComplete="email"
                size="small"
                variant="outlined"
                color="primary"
                fullWidth
                value={recipientEmailTwo}
                inputProps={{ className: 'shareArticle_input' }}
                InputLabelProps={{ className: 'shareArticle_input_label' }}
                onChange={this.handleRecipientEmailTwo}
                error={!!(error && error.recipientEmailTwo)}
                helperText={error && error.recipientEmailTwo ? error.recipientEmailTwo : ' '}
                variant="filled"
              />
            </div>
          </div>
        </div>
        <div className="shareArticle_data_collapse">
          <span><i onClick={this.addingRecipient} className="fa fa-plus-circle" aria-hidden="true" /></span>
        </div>
        <div className="shareArticle_data_input_section_collapse">
          <Collapse in={addingRecipient} addEndListener={() => { }}>
            <div className="AddUser_col">
              <div className="shareArticle_data">
                <TextField
                  id="recipientEmailThree"
                  type="text"
                  autoComplete="email"
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  value={recipientEmailThree}
                  inputProps={{ className: 'shareArticle_input' }}
                  InputLabelProps={{ className: 'shareArticle_input_label' }}
                  onChange={this.handleRecipientEmailThree}
                  error={!!(error && error.recipientEmailThree)}
                  helperText={error && error.recipientEmailThree ? error.recipientEmailThree : ' '}
                  variant="filled"
                />
              </div>
            </div>
          </Collapse>
        </div>
        <p className="shareArticleForm_message_header"> Message (100 Words) </p>

        <div className="shareArticle_data">

          <TextareaAutosize
            id="message"
            type="text"
            autoComplete="share-notes"
            size="small"
            variant="outlined"
            color="primary"
            rowsMin={5}
            value={message}
            onChange={this.handleMessage}
            variant="filled"
          />
        </div>

        <div className="shareArticle_data_actions">
          <Button
            onClick={onClose}
            className="shareArticle_data_actions_cancel"
            variant="secondary-inverse"
            customVariant="shareArticle_button_cancel"
            customContent="Cancel"
          />
          <Button
            onClick={this.shareArticle}
            className="shareArticle_data_actions_send"
            variant="secondary-inverse"
            customVariant="shareArticle_button_send"
            customContent="Send Now"
          />
        </div>
      </>
    );
  }
}

export default ShareArticleForm;
