import React, { Component } from 'react';
import * as Proptypes from 'prop-types';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import { bindActionCreators } from 'redux';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { connect } from 'react-redux';
import Button from '../Button/Button';
import '../../assets/svgs/logo.svg';
import './Header.scss';
import { storeQuestion } from '../../actions/navigationDetails/navigationDetails';
import { resetNav, resetSearchResult } from '../../actions/general';

/**
 * Header Component which loads the sofie logo, welcome message and nav buttons.
 */
class Header extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      dropdownStatus: false
    };
    this.navTo = this.navTo.bind(this);
    this.isActive = this.isActive.bind(this);
    this.handleDropdown = this.handleDropdown.bind(this);
    this.goToHome = this.goToHome.bind(this);
  }

  /**
   * On click for drowpdown
   */
  handleDropdown() {
    const { dropdownStatus } = this.state;
    const { parentCallback, activeFunction } = this.props;
    if (activeFunction) {
      parentCallback(!dropdownStatus);
    }
    this.setState({
      dropdownStatus: !dropdownStatus
    });
  }

  /**
   * On click open cliented
   */
  // eslint-disable-next-line class-methods-use-this
  redirectToClientEd() {
    ReactGA.event({
      category: 'Header',
      action: 'redirect-to-cliented'
    });
    ReactGA4.event('redirect-to-cliented');
    window.open(CLIENTED_URL, '_blank');
  }

  /**
   * On click take user to homepage
   */
  goToHome() {
    const { resetNavigation, resetSearchRes, history } = this.props;
    history.push('/ask');
    resetNavigation();
    resetSearchRes();
  }

  /**
   * Checks if the page associated with the link is currently being displayed.
   * @param {string} path - current url path
   * @returns {boolean} - if page associated with link is being shown
   */
  isActive(path) {
    const { location } = this.props;
    return (location.localeCompare(path) === 0);
  }

  /**
   * Handles changing the page when a header button is clicked.
   * @param {string} path - path to navigate to
   */
  navTo(path) {
    const { history, navigationDetails } = this.props;

    if (path === '/ask') {
      history.push(navigationDetails.askPath === '' ? navigationDetails.question : navigationDetails.askPath);
    } else {
      history.push(path);
    }
  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const {
      user, embed, location, headerImage
    } = this.props;

    const userCreatedDate = new Date(user.createdDate);
    const cutOverDate = new Date(CUTOVER_DATE);

    const canSeeProfile = !embed && (user.role === 5 || user.role === 6)
      && userCreatedDate < cutOverDate;

    const imageDisplay = headerImage ? 'Header_image' : 'Header_image_disable';

    return (
      <div className="Header">
        <Navbar bg="white" expand="lg">
          <Navbar.Brand>
            {location.includes('/view-articles') || location.includes('/favourite-articles')
              ? (
                <Button
                  variant="custom"
                  customVariant={imageDisplay}
                  onClick={() => this.goToHome()}
                  customContent={(
                    <Image
                      src={`${UM_URL}svg/logo.svg`}
                      className="logo img-responsive"
                      width="175px"
                    />
                )}
                  squareButton
                  disabled={location.includes('/ask') || location.includes('/view-articles') || location.includes('/favourite-articles')}
                />
              )
              : (
                <Button
                  variant="custom"
                  onClick={() => this.goToHome()}
                  customContent={(
                    <Image
                      src={`${UM_URL}svg/logo.svg`}
                      className="logo img-responsive"
                      width="175px"
                    />
                )}
                  squareButton
                  disabled={location.includes('/ask') || location.includes('/view-articles') || location.includes('/favourite-articles')}
                />
              )}

            {user.companyID === 48 && ( // For Purina
              <Image
                src={`${UM_URL}ui-images/purinaLogo.png`}
                className="logo img-responsive"
                width="175px"
              />
            )}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={this.handleDropdown} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="Header_links">
              <p className="Header_text">
                {`Welcome ${user.firstName}!`}
              </p>

              <Button
                onClick={() => this.navTo('/ask')}
                variant="custom"
                customVariant={location.includes('/ask') || location.includes('/view-articles') ? 'Header_active' : 'Header_button'}
                customContent="Ask Sofie"
                squareButton
                disabled={location.includes('/ask') || location.includes('/view-articles')}
              />
              {canSeeProfile && (
                <Button
                  onClick={() => this.navTo('/profile')}
                  variant="custom"
                  customVariant={this.isActive('/profile') ? 'Header_active' : 'Header_button'}
                  customContent="My Profile"
                  squareButton
                  disabled={location.localeCompare('/profile') === 0}
                />
              )}
              <Button
                onClick={() => this.navTo('/favourite')}
                variant="custom"
                customVariant={this.isActive('/favourite') ? 'Header_active' : 'Header_button'}
                customContent="Favorites"
                squareButton
                disabled={location.localeCompare('/favourite') === 0}
              />
              <Button
                onClick={this.redirectToClientEd}
                variant="custom"
                customVariant={this.isActive(CLIENTED_URL) ? 'Header_active' : 'Header_button'}
                customContent="ClientEd"
                squareButton
                disabled={location.localeCompare(CLIENTED_URL) === 0}
              />

              {!embed && (
                <Button
                  onClick={() => this.navTo('/logout')}
                  variant="custom"
                  customVariant={this.isActive('/logout') ? 'Header_active' : 'Header_button'}
                  customContent="Logout"
                  squareButton
                  disabled={location.localeCompare('/logout') === 0}
                />
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{ user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    navigationDetails: state.navigationDetails
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{ setSelectedArticles: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    storeQuery: storeQuestion,
    resetNavigation: resetNav,
    resetSearchRes: resetSearchResult
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

Header.propTypes = {
  embed: Proptypes.bool.isRequired,
  user: Proptypes.shape({
    firstName: Proptypes.string,
    lastName: Proptypes.string,
    role: Proptypes.number,
    companyID: Proptypes.number,
    createdDate: Proptypes.string
  }).isRequired,
  history: Proptypes.shape({
    push: Proptypes.func
  }).isRequired,
  location: Proptypes.string.isRequired,
  headerImage: Proptypes.node,
  resetSearchRes: Proptypes.func.isRequired,
  resetNavigation: Proptypes.func.isRequired,
  navigationDetails: Proptypes.shape({
    askPath: Proptypes.string,
    question: Proptypes.string,
  }).isRequired,
  parentCallback: Proptypes.func,
  activeFunction: Proptypes.bool
};

Header.defaultProps = {
  headerImage: <></>,
  parentCallback: () => {},
  activeFunction: false,
};
